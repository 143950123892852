import { DIMENSION_PREFERENCE_DENOMINATIONS } from 'state-domains/constants';

export type GenericWithProps<T> = (baseComponent: React.ReactNode) => React.ComponentType<T>;

export interface HeaderFieldInfo {
    id: string;
    name: string;
    label: string;
    type: string;
    dynamicFilter: boolean;
    values?: HeaderFieldInfoValues[];
    operatorLabelOverrides?: Record<string, string>;
}

export interface HeaderFieldInfoValues {
    id: string;
    value: any;
    index: number;
}

export enum ReadOnlyReasons {
    REVIEWER = 'REVIEWER',
    ACTIVITY_GROUP = 'ACTIVITY_GROUP',
    INVALID_DRILLHOLE_STATE = 'INVALID_DRILLHOLE_STATE',
    XRF_TABLE = 'XRF_TABLE',
    ASSIGNED_USER = 'ASSIGNED_USER',
}

export enum TimeType {
    SECONDS = 'SECONDS',
    MINUTES = 'MINUTES',
    DATE = 'DATE',
}

export interface Preferences {
    rowHeight: string;
    listEditor: { width: number; height: number };
    listColourWidth: string;
    showRowNumbers: boolean;
    rowsPerPage?: number;
    pasteOptions: string;
}

export enum PASTE_OPTIONS {
    FAIL_FAST = 'failFast',
    PASTE_AS_MUCH_AS_POSSIBLE = 'pasteAsMuchAsPossible',
}

export const DEFAULT_PREFERENCES: Preferences = {
    rowHeight: DIMENSION_PREFERENCE_DENOMINATIONS.DEFAULT.toString(),
    listEditor: { width: 555, height: 400 },
    listColourWidth: DIMENSION_PREFERENCE_DENOMINATIONS.DEFAULT.toString(),
    showRowNumbers: true,
    pasteOptions: PASTE_OPTIONS.FAIL_FAST,
    // rowsPerPage defaults are governed by the PaginationControls
};
